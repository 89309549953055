import React, { useRef, useEffect, useState } from "react";
import messages from "./messages.js";
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  useIntl,
} from "react-intl";
import "./Styles/CaptionFlow.css";
import SubtitlesBackgroundGradient from "./assets/subtitles_background_gradient.png";
import { gsap } from "gsap";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { Range, getTrackBackground } from "react-range";
import { v4 as uuidv4 } from "uuid";
import * as animationData from "./assets/fliva_logo_animation";
import * as playToPauseAnimationData from "./assets/PlayToPause.json";
import * as pauseToPlayAnimationData from "./assets/PauseToPlay.json";
import { Draggable } from "gsap/Draggable";
import { Routes, Route, Outlet, useParams } from "react-router-dom";
import { Mixpanel } from "../../Common/Mixpanel";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useQuery, useMutation } from "react-apollo";
import {
  CAPTIONEDIT_QUERY,
  CAPTIONEDIT_UPDATE_MUTATION,
  CAPTIONEDIT_PROOFREAD_MUTATION,
  captionFlowListToCaptionList,
  captionListToCaptionFlowEntryList,
} from "../../api/captions";
import { captionEntryListToVtt } from "../../api/captions/vtt";
import { SubtitleEntry } from "./SubtitleEntry";

gsap.registerPlugin(ScrollToPlugin, Draggable);

function convertTimestampToString(value, useDeci = false) {
  let frames = parseInt(((value % 100) * 100) % 100);
  let seconds = parseInt(value % 60);
  let minutes = parseInt((value % 3600) / 60);
  if (useDeci) {
    return (
      normalizeDigits(minutes) +
      ":" +
      normalizeDigits(seconds) +
      ":" +
      normalizeDigits(frames)
    );
  } else {
    return normalizeDigits(minutes) + ":" + normalizeDigits(seconds);
  }
}

//
function normalizeDigits(number) {
  if (number > -10 && number < 10) {
    return "0" + number;
  } else {
    return number;
  }
}

String.prototype.hashCode = function () {
  var hash = 0;
  if (this.length == 0) return hash;
  for (i = 0; i < this.length; i++) {
    char = this.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

const CaptionFlowApiWrapper = () => {
  let options = useParams();

  const params = {
    variables: { captionId: options.captionId, videoId: options.videoId },
  };
  const { loading, data, error } = useQuery(CAPTIONEDIT_QUERY, params);

  const [saveCaption, newData] = useMutation(CAPTIONEDIT_UPDATE_MUTATION);

  const [proofRead, proofreadData] = useMutation(
    CAPTIONEDIT_PROOFREAD_MUTATION,
  );

  const proofreadSentences = (sentences, callback) => {
    const result = proofRead({ variables: { sentences: sentences } });

    result
      .then((done) => {
        console.log("Proofread done: ", done.data);
        if (callback) {
          callback(done.data.proofreadSentences);
        }
      })
      .catch((v) => console.error(v));
  };

  const save = (caption, callback) => {
    const variables = {
      id: options.captionId,
    };
    if (caption.source) {
      variables["source"] = JSON.stringify(caption.source);
    }
    if (caption.language) {
      variables["language"] = caption.language;
    }
    if (caption.captionType) {
      variables["captionType"] = caption.captionType;
    }
    if (caption.text) {
      variables["text"] = caption.text;
    }
    if (caption.style) {
      variables["style"] = JSON.stringify(caption.style);
    }
    const result = saveCaption({ variables: variables });
    result
      .then((done) => {
        Mixpanel.track("Updated caption", {});
        if (callback) callback();
      })
      .catch((v) => console.error(v));
  };

  const saveAndProduce = (subtitles, style, callback) => {
    const source = captionFlowListToCaptionList(subtitles);
    const caption = {
      source: source,
      style: style,
      text: captionEntryListToVtt(source),
    };
    save(caption, callback);
  };
  const saveWithoutProduction = (subtitles, style, callback) => {
    const source = captionFlowListToCaptionList(subtitles);
    const caption = {
      source: source,
      style: style,
    };
    save(caption, callback);
  };

  if (error) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <p>
          <FormattedMessage {...messages.captionsLoadFailed} />
        </p>
      </div>
    );
  }
  if (loading) {
    return (
      <h1>
        <FormattedMessage {...messages.loading} />
      </h1>
    );
  }

  const source = JSON.parse(data.caption.source);

  const subtitles = captionListToCaptionFlowEntryList(source);

  for (let i = 0; i < subtitles.length; i++) {
    subtitles[i].content = subtitles[i].content.replace(/[|]/g, "\n");
    subtitles[i].key = uuidv4();
  }
  const videoUrl = data.video.publicUrl;
  const posterUrl = data.video.publicPoster;
  const title = data.video.title;

  const captionFlowSettings = normalizeCaptionflowSettings(
    data.captionFlowSettings,
  );
  console.log("CAPTIONFLOW SETTINGS");
  console.log(captionFlowSettings);
  console.log(captionFlowSettings?.font?.fontFamily);
  console.log(captionFlowSettings?.font?.fontUrl);

  if (
    captionFlowSettings?.font?.fontFamily &&
    captionFlowSettings?.font?.fontUrl
  ) {
    console.log("Trying to load font");
    try {
      const font = new FontFace(
        captionFlowSettings.font.fontFamily,
        `url(${captionFlowSettings.font.fontUrl})`,
      );
      font.load().then((loaded) => {
        document.fonts.add(loaded);
        console.log("Font loaded");
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <CaptionFlow
      settings={captionFlowSettings}
      subtitlesSource={subtitles}
      videoUrl={videoUrl}
      posterUrl={posterUrl}
      title={title}
      saveAndProduce={saveAndProduce}
      saveWithoutProduction={saveWithoutProduction}
      proofreadSentences={proofreadSentences}
    />
  );
};

const normalizeCaptionflowSettings = (settings) => {
  console.log(settings);
  const defaults = {
    showBoxSetting: true,
    legacy: true,
    backgroundColor: {
      r: 0,
      g: 0,
      b: 0,
      a: 0.5,
    },
    fontColor: {
      r: 1,
      g: 1,
      b: 1,
      a: 1,
    },
    font: {
      fontFamily: "Home",
      weight: "normal",
      fontUrl: null,
    },
    fontSize: 42,
    textPosition: {
      align: "CENTER",
      valign: "BOTTOM",
      x: 10,
      y: 30,
    },
  };

  if (settings) {
    settings["showBoxSetting"] = false;
    return settings;
  } else {
    return defaults;
  }
};

const CaptionFlow = ({
  settings,
  subtitlesSource,
  videoUrl,
  posterUrl,
  title,
  saveWithoutProduction,
  saveAndProduce,
  proofreadSentences,
}) => {
  // VARS
  const { formatMessage } = useIntl();
  var scrubberInstance = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEditingInOutPoints, setIsEditingInOutPoints] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isFullHeight, setIsFullHeight] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [activeSubtitle, setActiveSubtitle] = useState("");
  const [activeSubtitleIndex, setActiveSubtitleIndex] = useState(-1);
  const activeSubtitleIndexRef = useRef();
  activeSubtitleIndexRef.current = activeSubtitleIndex;
  const [optionsIndex, setOptionsIndex] = useState(0);
  const [activeSubtitleCaretPosition, setActiveSubtitleCaretPosition] =
    useState(-1);
  const [activeSubtitleElement, setActiveSubtitleElement] = useState(null);
  const [previousSubtitleIndex, setPreviousSubtitleIndex] = useState(-1);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showDeleteSubtitlesModal, setShowDeleteSubtitlesModal] =
    useState(false);
  const [showSubtitleBackground, setShowSubtitleBackground] = useState(true);
  const [showSavingChanges, setShowSavingChanges] = useState(false);
  const [showSendToProductionPopup, setShowSendToProductionPopup] =
    useState(false);
  const [showProducingVideoScreen, setShowProducingVideoScreen] =
    useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [showShortcutsScreen, setShowShortcutsScreen] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const [subtitles, setSubtitles] = useState(subtitlesSource);
  const subtitlesRef = useRef();
  subtitlesRef.current = subtitles;
  const [portraitMode, setPortraitMode] = useState(true);
  const [resolution, setResolution] = useState({ width: 0, height: 0 });
  const [scaleRatio, setScaleRatio] = useState(0.3);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [subtitlesHeight, setSubtitlesHeight] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  const videoSource = videoUrl.replace(/\-captions\b/g, "");

  // REFS
  const scrubberHandle = useRef(null);
  const scrubberContainer = useRef(null);
  const progressBar = useRef(null);
  const subtitlesContainerRef = useRef(null);
  const subtitlesWrapper = useRef(null);
  const videoRef = useRef(null);
  const videoWrapper = useRef(null);

  useEffect(() => {
    if (scrubberContainer.current) {
      gsap.set(scrubberHandle.current, {
        x:
          ((scrubberContainer.current.clientWidth -
            scrubberHandle.current.clientWidth) *
            progress) /
          100,
      });
    }
  }, [progress]);
  useEffect(() => {
    if (portraitMode) {
      setVideoWidth(1080 * scaleRatio);
      if (isFullHeight) {
        setVideoHeight(1920 * scaleRatio);
      } else {
        setVideoHeight(1350 * scaleRatio);
      }
    } else {
      setVideoWidth(1920 * scaleRatio);
      setVideoHeight(1080 * scaleRatio);
    }
    // handleResize();
  }, [scaleRatio]);

  useEffect(() => {
    if (portraitMode) {
      setVideoWidth(1080 * scaleRatio);
      if (isFullHeight) {
        setVideoHeight(1920 * scaleRatio);
      } else {
        setVideoHeight(1350 * scaleRatio);
      }
    } else {
      setVideoWidth(1920 * scaleRatio);
      setVideoHeight(1080 * scaleRatio);
    }
    // handleResize();
  }, [portraitMode]);

  useEffect(() => {
    setVideoWidth(1080 * scaleRatio);
    if (isFullHeight) {
      setVideoHeight(1920 * scaleRatio);
    } else {
      setVideoHeight(1350 * scaleRatio);
    }
    // handleResize();
  }, [isFullHeight]);

  useEffect(() => {
    if (
      activeSubtitleIndex > -1 &&
      activeSubtitleIndex < subtitles.length &&
      subtitles.length > 0
    ) {
      setActiveSubtitle(subtitles[activeSubtitleIndex].content);
    }
  }, [subtitles]);

  useEffect(() => {
    if (
      activeSubtitleIndex != previousSubtitleIndex &&
      activeSubtitleIndex != -1
    ) {
      let duration = 0.4;
      if (isDragging) duration = 0.125;
      gsap.to(subtitlesContainerRef.current, {
        duration: duration,
        scrollTo: { y: "#sub-" + activeSubtitleIndex, offsetY: 2 },
        ease: "power2.inOut",
      });
    }
  }, [activeSubtitleIndex, previousSubtitleIndex]);

  useEffect(() => {
    scrubberInstance = Draggable.create(scrubberHandle.current, {
      type: "x",
      bounds: scrubberContainer.current,
      onPress: function () {
        setIsEditingInOutPoints(false);
        setIsDragging(true);
        setActiveSubtitle("");
        setActiveSubtitleIndex((prev) => {
          setPreviousSubtitleIndex(prev);
          return -1;
        });
      },
      onDrag: function () {
        onScrubberDrag(this);
        scrubberInstance[0].update();
      },
      onRelease: function () {
        setIsDragging(false);
        scrubberInstance[0].update();
      },
      range: false,
    });

    setProgress(0); // Set progress bar to almost zero
    gsap.set(progressBar.current, { maxWidth: "0px" });

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("resize", handleResize);

    if (videoRef.current) {
      videoRef.current.addEventListener("loadedmetadata", function (e) {
        setResolution({
          width: videoRef.current.videoWidth,
          height: videoRef.current.videoHeight,
        });
        handleResize();
        for (let i = 0; i < subtitles.length; i++) {
          let el = document.getElementById("sub-" + i);
          subtitleChanged(el.getElementsByTagName("textarea")[0], i);
        }
        const timer = setTimeout(() => {
          setShowLoadingScreen(false);
        }, 500);
        return () => clearTimeout(timer);
      });
    }
  }, []);

  const handleKeyDown = (e) => {
    if (
      e.keyCode == 32 &&
      document.activeElement.tagName != "TEXTAREA" &&
      !e.ctrlKey
    ) {
      playPauseHit();
    } else if (e.ctrlKey && e.shiftKey) {
      if (e.keyCode == 8 || e.keyCode == 68) {
        // Backspace (delete) or D
        playPauseHit("pause");
        deleteSubtitle(activeSubtitleIndexRef.current);
        if (activeSubtitleIndexRef.current > 0) {
          setActiveSubtitleIndex(activeSubtitleIndexRef.current - 1);
        }
        let idStr = "sub-" + activeSubtitleIndexRef.current;
        let textarea = document
          .getElementById(idStr)
          .getElementsByClassName("input")[0];
        textarea.setSelectionRange(
          textarea.value.length,
          textarea.value.length,
        );
        textarea.focus();
      } else if (e.keyCode == 70) {
        // F key (fill)
        playPauseHit("pause");
        let sub = {
          inPoint: subtitlesRef.current[activeSubtitleIndexRef.current].inPoint,
          outPoint:
            subtitlesRef.current[activeSubtitleIndexRef.current + 1].inPoint,
          content: subtitlesRef.current[activeSubtitleIndexRef.current].content,
          key: uuidv4(),
        };
        let subtitlesCopy = [...subtitlesRef.current];
        subtitlesCopy.splice(activeSubtitleIndexRef.current, 1, sub);
        setSubtitles(subtitlesCopy);
      } else if (e.keyCode == 83) {
        // S key (split)
        playPauseHit("pause");
        splitSubtitle(activeSubtitleIndexRef.current);
      } else if (e.keyCode == 76) {
        // L key (loop)
      } else if (e.keyCode == 38) {
        // UP
        selectPreviousSubtitle();
      } else if (e.keyCode == 40) {
        // DOWN
        selectNextSubtitle();
      } else if (e.keyCode == 13) {
        // ENTER
        playPauseHit("pause");
        if (activeSubtitleIndexRef.current != -1) {
          let idStr = "sub-" + activeSubtitleIndexRef.current;
          let textarea = document
            .getElementById(idStr)
            .getElementsByClassName("input")[0];
          textarea.setSelectionRange(
            textarea.value.length,
            textarea.value.length,
          );
          textarea.focus();
        }
      }
    } else if (e.ctrlKey) {
      if (e.keyCode == 32) {
        // SPACE
        playPauseHit();
      }
    }
  };

  const selectPreviousSubtitle = () => {
    if (activeSubtitleIndexRef.current > 0) {
      goToSubtitle(activeSubtitleIndexRef.current - 1);
      let idStr = "sub-" + activeSubtitleIndexRef.current;
      let textarea = document
        .getElementById(idStr)
        .getElementsByClassName("input")[0];
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      textarea.focus();
    }
  };

  const selectNextSubtitle = () => {
    if (activeSubtitleIndexRef.current == -1) {
      goToSubtitle(0);
    } else if (activeSubtitleIndexRef.current < subtitles.length - 1) {
      goToSubtitle(activeSubtitleIndexRef.current + 1);
    }
    let idStr = "sub-" + activeSubtitleIndexRef.current;
    let textarea = document
      .getElementById(idStr)
      .getElementsByClassName("input")[0];
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    textarea.focus();
  };

  const handleResize = () => {
    if (videoRef.current.videoWidth == 0 && videoRef.current.videoHeight == 0)
      return null;
    setViewportHeight(window.innerHeight);
    if (window.innerWidth < 1024) {
      // MOBILE VIEW
      setIsMobile(true);
      if (videoRef.current.videoWidth > videoRef.current.videoHeight) {
        // Landscape mode
        setPortraitMode(false);
        setScaleRatio(window.innerWidth / 1920);
        // setSubtitlesHeight(window.innerHeight - ((1080 * (window.innerWidth / 1920)) - 164));
        let h = window.innerHeight - videoRef.current.clientHeight - 164;
        setSubtitlesHeight(h);
      } else {
        // Portrait mode
        setPortraitMode(true);
        if (videoRef.current.videoHeight == 1920) {
          // temporary fix, since the editor does not support 1920px height
          setIsFullHeight(true);
          setScaleRatio((window.innerHeight / 1920) * 0.4);
          let h = 1920 * ((window.innerHeight / 1920) * 0.4);
          setSubtitlesHeight(window.innerHeight - (56 + h + 95));
        } else {
          setScaleRatio((window.innerHeight / 1350) * 0.5);
          setSubtitlesHeight(1350 * ((window.innerHeight / 1350) * 0.5));
        }
      }
      // setSubtitlesHeight(window.innerHeight - (56 + videoHeight + 95));
    } else {
      // DESKTOP VIEW
      setIsMobile(false);
      if (videoRef.current.videoWidth > videoRef.current.videoHeight) {
        // Landscape mode
        setPortraitMode(false);
        setScaleRatio((window.innerWidth / 1920) * 0.4);
        setSubtitlesHeight(1080 * ((window.innerWidth / 1920) * 0.4));
      } else {
        // Portrait mode
        setPortraitMode(true);
        if (videoRef.current.videoHeight == 1920) {
          // temporary fix, since the editor does not support 1920px height
          setIsFullHeight(true);
          setScaleRatio((window.innerHeight / 1920) * 0.7);
          setSubtitlesHeight(1920 * ((window.innerHeight / 1920) * 0.7));
        } else {
          setScaleRatio((window.innerHeight / 1350) * 0.6);
          setSubtitlesHeight(1350 * ((window.innerHeight / 1350) * 0.6));
        }
      }
    }
  };

  const onScrubberDrag = (scrubber) => {
    let scrubberProgress =
      scrubber.x /
      (scrubberContainer.current.clientWidth -
        scrubberHandle.current.clientWidth);
    let t = videoRef.current.duration * scrubberProgress;
    setVideoCurrentTime(t);
    setCurrentTime(t);
    setProgress((t / videoRef.current.duration) * 100);
    updateActiveSubtitle(t);

    let progressBarWidth =
      (scrubberContainer.current.clientWidth *
        ((t / videoRef.current.duration) * 100)) /
      100;
    gsap.set(progressBar.current, { maxWidth: progressBarWidth + "px" });
  };
  const setVideoCurrentTime = (val) => {
    videoRef.current.currentTime = val;
  };

  // UPDATE VIDEO REF
  useEffect(() => {
    if (!videoRef.current) return;
    if (!videoRef || isNaN(videoRef.current.duration)) return;
    setDuration(videoRef.current.duration);
  });

  // UPDATE TIME FUNCTION
  const updateTime = () => {
    if (!videoRef.current || isDragging || !isPlaying) return;
    if (currentTime == videoRef.current.currentTime) return;

    let progressPercent = (
      (videoRef.current.currentTime / duration) *
      100
    ).toFixed(2);
    setCurrentTime(videoRef.current.currentTime);
    setProgress(progressPercent);
    setIsEditingInOutPoints(false);

    let progressBarWidth =
      (scrubberContainer.current.clientWidth * progressPercent) / 100;
    gsap.set(progressBar.current, { maxWidth: progressBarWidth + "px" });

    updateActiveSubtitle(videoRef.current.currentTime);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      updateTime();
    }, 60);
    return () => clearInterval(interval);
  });

  const updateActiveSubtitle = (cTime) => {
    let foundASubtitle = false;
    for (let i = 0; i < subtitles.length; i++) {
      let sub = subtitles[i];
      if (sub.inPoint < cTime && sub.outPoint > cTime) {
        foundASubtitle = true;
        if (sub.content != activeSubtitle) {
          setActiveSubtitle(sub.content);
          setActiveSubtitleIndex(i);
          break;
        }
      }
    }
    if (!foundASubtitle) {
      setActiveSubtitle("");
      setActiveSubtitleIndex(-1);
    }
  };
  const goToSubtitle = (index, isEditingInOut = false) => {
    if (!isEditingInOut) setIsEditingInOutPoints(false);
    // gsap.to(subtitlesContainerRef.current, {duration: .5, scrollTo: {y: "#sub-"+index, offsetY: 2}, ease:"power1.inOut"});
    setActiveSubtitle(subtitles[index].content);
    setActiveSubtitleIndex(index);
    videoRef.current.currentTime = subtitles[index].inPoint;
    setCurrentTime(videoRef.current.currentTime);
    let progressPercent = (
      (videoRef.current.currentTime / videoRef.current.duration) *
      100
    ).toFixed(2);
    setProgress(progressPercent);
    gsap.set(scrubberHandle.current, {
      x:
        ((scrubberContainer.current.clientWidth -
          scrubberHandle.current.clientWidth) *
          progressPercent) /
        100,
    });
    let progressBarWidth =
      (scrubberContainer.current.clientWidth * progressPercent) / 100;
    gsap.set(progressBar.current, { maxWidth: progressBarWidth + "px" });
  };
  const subtitleHit = (index) => {
    if (isPlaying) playPauseHit();
    goToSubtitle(index);
  };
  const subtitleChanged = (elem, index) => {
    elem.style.height = "5px";
    elem.style.height = elem.scrollHeight + "px";
    let subtitlesCopy = [...subtitles];
    subtitlesCopy[index].content = elem.value;
    setSubtitles(subtitlesCopy);
  };

  const splitSubtitle = (index) => {
    let subtitlesCopy = [...subtitlesRef.current];
    let completeStr = subtitlesCopy[index].content;
    let oldStr = "";
    let newStr = "";
    let newLineIndex = completeStr.indexOf("\n");
    let fullStopIndex = completeStr.indexOf(".");
    let commaIndex = completeStr.indexOf(",");
    let textarea = document
      .getElementById("sub-" + activeSubtitleIndexRef.current)
      .getElementsByClassName("input")[0];
    let caretPosition = -1;
    if (textarea.selectionStart > 0) {
      caretPosition = textarea.selectionStart;
    }

    // Try to get an index of a space somewhere in the middle of the string
    let spaceIndex = completeStr.indexOf(" ");
    var spaceIndexes = [];
    for (var i = 0; i < completeStr.length; i++) {
      if (completeStr[i] === " ") spaceIndexes.push(i);
    }
    if (spaceIndexes.length > 1) {
      spaceIndex = spaceIndex[Math.round(spaceIndexes.length / 2)];
    }

    let sub1 = {
      inPoint: subtitlesRef.current[index].inPoint,
      outPoint: 0,
      content: "",
      key: uuidv4(),
    };
    let sub2 = { inPoint: 0, outPoint: 0, content: "", key: uuidv4() };

    if (caretPosition > -1 && caretPosition < completeStr.length) {
      // Split at the caret position
      oldStr = completeStr.substring(0, caretPosition);
      newStr = completeStr.substring(caretPosition, completeStr.length);
    } else if (newLineIndex > -1) {
      // There is a line break in the sentence, split the text
      oldStr = completeStr.substring(0, newLineIndex);
      newStr = completeStr.substring(newLineIndex + 1, completeStr.length);
    } else if (fullStopIndex > -1 && completeStr.length > fullStopIndex + 1) {
      // There is a full stop in the sentence, split the text
      oldStr = completeStr.substring(0, fullStopIndex + 1);
      newStr = completeStr.substring(fullStopIndex + 2, completeStr.length);
    } else if (commaIndex > -1 && completeStr.length > commaIndex + 1) {
      // There is a comma in the sentence, split the text here
      oldStr = completeStr.substring(0, commaIndex + 1);
      newStr = completeStr.substring(commaIndex + 2, completeStr.length);
    } else {
      // Just split the text in half at a space
      oldStr = completeStr.substring(0, spaceIndex);
      newStr = completeStr.substring(spaceIndex + 1, completeStr.length);
    }

    sub2.content = newStr;
    sub2.inPoint = Number(
      (
        (subtitlesCopy[index].outPoint - subtitlesCopy[index].inPoint) / 2 +
        subtitlesCopy[index].inPoint
      ).toFixed(2),
    );
    sub2.outPoint = subtitlesCopy[index].outPoint - 0.01;

    sub1.content = oldStr;
    sub1.outPoint = sub2.inPoint;
    subtitlesCopy.splice(index, 1, sub1);
    subtitlesCopy.splice(index + 1, 0, sub2);

    setSubtitles(subtitlesCopy);

    setShowOptionsModal(false);
    setOptionsIndex(-1);
  };

  const addSubtitleAfter = (index) => {
    let subtitlesCopy = [...subtitlesRef.current];
    let newSub = { inPoint: 0, outPoint: 0, content: "", key: uuidv4() };

    if (index == -1) {
      if (subtitlesRef.current.length > 0) {
        newSub.inPoint = subtitlesCopy[subtitles.length - 1].outPoint;
        newSub.outPoint = subtitlesCopy[subtitles.length - 1].outPoint + 1;
      } else {
        newSub.inPoint = 0;
        newSub.outPoint = 1;
      }
      subtitlesCopy.push(newSub);
    } else {
      if (index < subtitlesCopy.length - 1) {
        if (subtitlesCopy[index + 1].inPoint == subtitlesCopy[index].outPoint) {
          subtitlesCopy[index + 1].inPoint += 0.5; // Make room for an extra subtitle
        }
      }
      newSub.inPoint = subtitlesCopy[index].outPoint;
      if (index < subtitlesCopy.length - 1) {
        newSub.outPoint = subtitlesCopy[index + 1].inPoint;
      } else {
        newSub.outPoint = subtitlesCopy[index].outPoint + 1;
      }

      subtitlesCopy.splice(index + 1, 0, newSub);
    }
    setSubtitles(subtitlesCopy);

    setShowOptionsModal(false);
    setOptionsIndex(-1);
  };

  const deleteSubtitle = (index) => {
    let subtitlesCopy = [...subtitlesRef.current];
    if (subtitlesCopy.length == 1) {
      subtitlesCopy = [{ inPoint: 0, outPoint: 1, content: "" }];
    } else if (subtitlesCopy.length == 2) {
      subtitlesCopy.splice(-1);
    } else {
      subtitlesCopy.splice(index, 1);
    }

    setSubtitles(subtitlesCopy);

    setShowOptionsModal(false);
    setOptionsIndex(-1);
  };

  const emptyAllSubtitlesRequest = () => {
    let empty = [];
    for (let i = 0; i < subtitlesRef.current.length; i++) {
      empty.push({
        inPoint: subtitlesRef.current[i].inPoint,
        outPoint: subtitlesRef.current[i].outPoint,
        content: "",
        key: uuidv4(),
      });
    }
    setSubtitles(empty);
  };

  const deleteAllSubtitlesRequest = () => {
    setShowDeleteSubtitlesModal(true);
  };

  const deleteAllSubtitlesConfirmed = () => {
    setShowDeleteSubtitlesModal(false);
    setSubtitles([]);
  };

  const playPauseHit = (val) => {
    if (val == "pause") {
      setIsPlaying(false);
      pauseVideo();
    } else if (val == "play") {
      setIsPlaying(true);
      playVideo();
    } else {
      setIsPlaying((prevState) => {
        if (!prevState) {
          playVideo();
        } else {
          pauseVideo();
        }
        return !prevState;
      });
    }
  };
  const playVideo = (evt) => {
    videoRef.current.play();
  };
  const pauseVideo = (evt) => {
    videoRef.current.pause();
  };

  const proofreadCaptions = () => {
    let sentences = subtitlesRef.current.map((sub) => {
      return sub.content;
    });
    setShowSavingChanges(true);
    proofreadSentences(sentences, (finalSentences) => {
      console.log("Sentences back: ", finalSentences);

      for (let i = 0; i < subtitlesRef.current.length; i++) {
        subtitlesRef.current[i].content = finalSentences[i];
      }

      setShowSavingChanges(false);
    });
  };

  const saveChanges = (evt) => {
    setShowSavingChanges(true);

    let subtitlesCopy = [];
    for (let i = 0; i < subtitlesRef.current.length; i++) {
      let obj = {
        inPoint: subtitlesRef.current[i].inPoint,
        outPoint: subtitlesRef.current[i].outPoint,
      };
      obj.content = subtitlesRef.current[i].content.replace(
        /\r?\n|\r|\n/g,
        "|",
      );
      subtitlesCopy.push(obj);
    }
    let style = {};
    if (!showSubtitleBackground) {
      style.BorderStyle = 0;
      style.Shadow = 2;
      style.Outline = 0;
    } else {
      style.BorderStyle = 4;
      style.Shadow = 2;
      style.Outline = 0;
    }
    saveWithoutProduction(subtitlesCopy, style, saveChangesCallback);
  };
  const saveChangesCallback = (val) => {
    setShowSavingChanges(false);
    setShowMenuPopup(false);
  };

  const saveAndProduceHit = (e) => {
    setShowSavingChanges(true);
    let subtitlesCopy = [];
    for (let i = 0; i < subtitlesRef.current.length; i++) {
      let obj = {
        inPoint: subtitlesRef.current[i].inPoint,
        outPoint: subtitlesRef.current[i].outPoint,
      };
      obj.content = subtitlesRef.current[i].content.replace(
        /\r?\n|\r|\n/g,
        "|",
      );
      subtitlesCopy.push(obj);
    }
    let style = {};
    if (!showSubtitleBackground) {
      style.BorderStyle = 0;
      style.Shadow = 2;
      style.Outline = 0;
    } else {
      style.BorderStyle = 4;
      style.Shadow = 2;
      style.Outline = 0;
    }
    saveAndProduce(subtitlesCopy, style, saveAndProduceCallback);
  };
  const saveAndProduceCallback = (e) => {
    setShowSavingChanges(false);
    setShowProducingVideoScreen(true);
  };

  const showShortcuts = () => {
    setShowShortcutsScreen(true);
  };

  const optionsHit = (val, index) => {
    setOptionsIndex(index);
    setShowOptionsModal(val);
  };
  const editInOutPointsHit = (index) => {
    if (isPlaying) playPauseHit(); // Pause the video, since we want to edit in/out points
    goToSubtitle(index, true);

    if (!isEditingInOutPoints) {
      setIsEditingInOutPoints(true);
    }

    setShowOptionsModal(false);
    setOptionsIndex(-1);
  };

  return (
    <div
      className="bg-[#F2F2F2] flex max-w-full lg:justify-center h-screen overflow-y-hidden flex-col"
      style={{ "--progress": progress + "%" }}
    >
      {/* PLAYER */}
      <div className="lg:flex relative lg:flex-row lg:justify-center lg:align-center">
        <div>
          {!isMobile ? (
            <CaptionFlowLogo />
          ) : (
            <div className="flex justify-start items-center p-4 py-4">
              <div className="flex flex-col basis-3/4 justify-center text-sm">
                <label className="text-[#D0D0D0] font-bold mr-2">
                  <FormattedMessage {...messages.title} />:
                </label>
                <label className="text-[#4b5564]">{title}</label>
              </div>
              <div className="flex justify-end basis-1/4 w-10 h-10">
                {!showMenuPopup ? (
                  <button
                    onClick={() => setShowMenuPopup(true)}
                    className="absolute z-50 w-10 h-10 flex justify-center items-center rounded"
                  >
                    <svg
                      className="w-6 h-6"
                      version="1.1"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g fill="none">
                        <path d="M0 0h24v24h-24Z"></path>
                        <path
                          stroke="#0C4C8A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7.505 3l8.995 9.027 -9 8.973"
                        ></path>
                      </g>
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => setShowMenuPopup(false)}
                    className="absolute z-50 w-10 h-10 rounded"
                  >
                    <svg
                      version="1.1"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <g fill="none">
                        <path
                          stroke="#FFFFFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M8 8l8 8"
                        ></path>
                        <path
                          stroke="#FFFFFF"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="M16 8l-8 8"
                        ></path>
                      </g>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="player flex justify-center bg-[#d0d0ce] lg:bg-inherit relative w-full">
            <div
              className="preview relative box-border overflow-hidden origin-top bg-[#000000]"
              ref={videoWrapper}
              style={{ width: videoWidth, height: videoHeight }}
            >
              {settings.showBoxSetting ? (
                <SubtitleBackgroundToggle
                  showSubtitleBackground={showSubtitleBackground}
                  setShowSubtitleBackground={setShowSubtitleBackground}
                />
              ) : (
                <></>
              )}
              {/* VIDEO */}
              <video
                id="videoContainer"
                className="relative block box-border object-contain w-full h-full origin-top"
                ref={videoRef}
                playsInline={true}
                src={videoSource}
                poster={posterUrl}
              ></video>
              {/* SUBTITLES */}
              <div>
                <div
                  className="absolute top-0 left-0 origin-top-left"
                  style={{ width: videoWidth, height: videoHeight }}
                >
                  <SubtitleEntry
                    videoWidth={videoWidth}
                    videoHeight={videoHeight}
                    isEditingInOutPoints={isEditingInOutPoints}
                    scaleRatio={scaleRatio}
                    resolution={resolution}
                    activeSubtitleIndex={activeSubtitleIndex}
                    activeSubtitle={activeSubtitle}
                    showSubtitleBackground={showSubtitleBackground}
                    settings={settings}
                  />
                </div>
              </div>
            </div>
            {/* SCRUBBER FOR IN/OUT POINTS */}
            {isEditingInOutPoints ? (
              <InOutPointEditor
                setIsEditingInOutPoints={setIsEditingInOutPoints}
                isEditingInOutPoints={isEditingInOutPoints}
                subtitles={subtitles}
                activeSubtitleIndex={activeSubtitleIndex}
                setVideoCurrentTime={setVideoCurrentTime}
                videoDuration={videoRef.current.duration}
                key={activeSubtitleIndex}
              />
            ) : null}
          </div>

          {/* CONTROL BAR */}
          <div className="control-bar block fixed lg:relative bottom-0 bg-[#F2F2F2] w-full h-[95px] z-40">
            <div ref={scrubberContainer} className="scrubber w-full relative">
              <div className="progress w-full h-1 bg-[#D0D0CE]">
                <div
                  className="progressed h-1 bg-[#0C4C8A] w-full"
                  id="scrubber-progress"
                  ref={progressBar}
                ></div>
              </div>
              <div
                className="handle absolute flex justify-center align-center w-14 lg:w-6 h-6 origin-center"
                style={{ top: "-9px" }}
                ref={scrubberHandle}
                id="scrubber-handle"
              >
                <div className="content flex justify-center items-center w-14 lg:w-6 h-6 bg-[#0C4C8A] absolute origin-center rounded-full">
                  <div className="absolute w-2 h-2 bg-[#D0D0D0] rounded-full"></div>
                </div>
              </div>
            </div>
            {/* INPUT 'RANGE' som scrubber */}
            <div className="content flex justify-between h-[92px] items-center">
              <div className="pl-8 lg:pl-2">
                <div className="currentTime text-[#3E3E3E] text-sm font-mono">
                  {convertTimestampToString(currentTime, true)}
                </div>
              </div>
              <div className="justify-center">
                <button
                  onClick={() => selectPreviousSubtitle()}
                  className="mr-4 w-8 h-8"
                >
                  <svg
                    version="1.1"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      d="M14,8l-4,4l4,4"
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      stroke="#D0D0CE"
                      fill="none"
                      strokeLinejoin="round"
                    ></path>
                    <path fill="none" d="M0,0h24v24h-24Z"></path>
                  </svg>
                </button>
                <button
                  onClick={(evt) => playPauseHit()}
                  className="h-12 w-12 rounded-full lg:mx-2 bg-brand-600 hover:bg-brand-500"
                >
                  {isPlaying ? <PlayToPauseIcon /> : <PauseToPlayIcon />}
                </button>
                <button
                  onClick={() => selectNextSubtitle()}
                  className="ml-4 w-8 h-8"
                >
                  <svg
                    version="1.1"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g fill="none">
                      <path d="M0 0h24v24h-24Z"></path>
                      <path
                        stroke="#D0D0CE"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M10 16l4-4 -4-4"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
              <div className="flex pr-8 lg:hidden">
                <button onClick={() => setShowTutorial(true)}>
                  <svg
                    className="w-8 h-8 stroke-[#0C4C8A]"
                    version="1.1"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      fill="none"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="9"></circle>
                      <path d="M9.68567 9.68567l-5.26376e-08 2.26865e-07c.236925-1.02113 1.16697-1.72914 2.21433-1.68567l-1.23132e-07 6.82006e-09c1.17217-.0649246 2.17705.828297 2.25 2 0 1.50391-2.15 2-2.15 3"></path>
                      <path d="M12.125 15.75v0c0 .0690356-.0559644.125-.125.125 -.0690356 0-.125-.0559644-.125-.125 0-.0690356.0559644-.125.125-.125"></path>
                      <path d="M12 15.625h-5.46392e-09c.0690356-3.01764e-09.125.0559644.125.125"></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Scrollable list of subtitles */}
        <div
          className="w-full lg:max-w-xl lg:pl-8 relative"
          ref={subtitlesWrapper}
        >
          <div className="hidden lg:flex block flex-col items-end justify-start h-20">
            <label className="font-bold text-sm text-[#D0D0D0]">
              <FormattedMessage {...messages.title} />:
            </label>
            <label className="text-sm">{title}</label>
          </div>
          <div
            className="subtitles flex-initial relative overflow-x-hidden overflow-y-scroll text-center"
            id="subtitles"
            ref={subtitlesContainerRef}
            style={{ height: subtitlesHeight + "px" }}
          >
            {subtitles.length > 0 ? (
              <div>
                {subtitles.map((item, index) => (
                  <Subtitle
                    currentTime={currentTime}
                    duration={duration}
                    subtitleHit={subtitleHit}
                    goToSubtitle={goToSubtitle}
                    subtitleChanged={subtitleChanged}
                    optionsHit={optionsHit}
                    activeSubtitleIndex={activeSubtitleIndex}
                    editInOutPointsHit={editInOutPointsHit}
                    key={item.key}
                    index={index}
                    inPoint={item.inPoint}
                    outPoint={item.outPoint}
                    item={item}
                  />
                ))}
              </div>
            ) : (
              <button
                onClick={() => addSubtitleAfter(-1)}
                className="py-2 mr-2 min-w-[12rem] text-xs m-4 text-white rounded-full shadow-sm bg-brand-500 hover:bg-brand-400 active:bg-brand-500"
              >
                <FormattedMessage {...messages.insertSubtitle} />
              </button>
            )}
            <div className="flex justify-center border-t border-[#D0D0D0] mt-4 mx-8 pt-4">
              <button
                onClick={() => {
                  emptyAllSubtitlesRequest();
                }}
                className="bg-[#D0D0D0] inline-block rounded-full text-[#4B5564] text-sm active:transition-shadow duration-100 lg:hover:shadow-lg lg:hover:bg-brand-500 active:bg-brand-500 lg:hover:text-[#FFFFFF] mx-2 py-2 px-8"
              >
                <FormattedMessage {...messages.emptySubtitlesLabel} />
              </button>
            </div>
            <div
              className="w-full bg-[#F2F2F2]"
              style={
                !isMobile
                  ? { height: videoHeight - 150 }
                  : { height: window.innerHeight - 164 - videoHeight }
              }
            ></div>
          </div>
          {!isMobile ? (
            <div
              id="gradient"
              className="pointer-events-none absolute bottom-0 w-full z-10 bg-gradient-to-t from-[#F2F2F2]"
              style={
                !isMobile
                  ? { height: videoHeight - 105 }
                  : { height: subtitlesWrapper.current.clientHeight - 105 }
              }
            ></div>
          ) : null}
          <div className="absolute hidden lg:flex w-full h-24 z-50 justify-end items-center">
            <button onClick={() => proofreadCaptions()}>Proofread</button>
            <button
              onClick={() => {
                setShowTutorial(true);
              }}
              className="transition-all duration-100 flex justify-center items-center group text-sm font-bold hover:bg-brand-400 p-2 h-12 w-12 rounded-full"
            >
              <svg
                className="h-6"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  className="transition-all duration-100 stroke-[#0c4c8a] group-hover:stroke-[#FFFFFF]"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinejoin="round"
                >
                  <path
                    d="M9.68567,9.68567A2.18034,2.18034,0,0,1,11.9,8a2.136,2.136,0,0,1,2.25,2c0,1.50391-2.15,2-2.15,3"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M12.12516,15.75016A.125.125,0,1,1,12,15.625a.125.125,0,0,1,.12516.12516"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M4.63147,17.15826a9.01378,9.01378,0,1,1,2.21027,2.21027L4,20Z"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </button>
            <button
              onClick={() => {
                setShowShortcutsScreen(true);
              }}
              className="transition-all duration-100 flex justify-center items-center group mr-4 text-sm font-bold hover:bg-brand-400 p-3 h-12 w-12 rounded-full"
            >
              <svg
                className="h-6"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  className="transition-all duration-100 stroke-[#0c4c8a] group-hover:stroke-[#FFFFFF] "
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinejoin="round"
                >
                  <path d="M8.5 16.5h7" />
                  <rect width="18" height="18" x="3" y="3" rx="5" />
                  <path d="M12.25 12.5l-.01 0c-.01.13-.12.24-.26.24 -.14-.01-.25-.12-.25-.26s.11-.25.24-.25l-.01 0c.13-.01.25.11.25.24 0 0 0 0 0 0" />
                  <path d="M8.25 12.5l-.01 0c-.01.13-.12.24-.26.24 -.14-.01-.25-.12-.25-.26s.11-.25.24-.25l-.01 0c.13-.01.25.11.25.24 0 0 0 0 0 0" />
                  <path d="M16.25 12.5l-.01 0c-.01.13-.12.24-.26.24 -.14-.01-.25-.12-.25-.26s.11-.25.24-.25l-.01 0c.13-.01.25.11.25.24 0 0 0 0 0 0" />
                  <path d="M16.25 8.5l-.01 0c-.01.13-.12.24-.26.24 -.14-.01-.25-.12-.25-.26s.11-.25.24-.25l-.01 0c.13-.01.25.11.25.24 0 0 0 0 0 0" />
                  <path d="M12.25 8.5l-.01 0c-.01.13-.12.24-.26.24 -.14-.01-.25-.12-.25-.26s.11-.25.24-.25l-.01 0c.13-.01.25.11.25.24 0 0 0 0 0 0" />
                  <path d="M8.25 8.5l-.01 0c-.01.13-.12.24-.26.24 -.14-.01-.25-.12-.25-.26s.11-.25.24-.25l-.01 0c.13-.01.25.11.25.24 0 0 0 0 0 0" />
                </g>
              </svg>
            </button>
            <button
              onClick={() => {
                saveChanges();
              }}
              className="h-12 mr-2 min-w-[12rem] text-sm font-bold text-white shadow-lg rounded-full bg-brand-300 hover:bg-brand-200 active:bg-brand-300"
            >
              <FormattedMessage {...messages.saveButtonText} />
            </button>
            <button
              onClick={() => {
                saveAndProduceHit();
              }}
              className="h-12 mr-2 min-w-[12rem] text-sm font-bold text-white rounded-full shadow-sm bg-brand-500 hover:bg-brand-400 active:bg-brand-500"
            >
              <FormattedMessage {...messages.produceButtonText} />
            </button>
          </div>
        </div>
      </div>
      {showOptionsModal ? (
        <OptionsPopup
          setShowOptionsModal={setShowOptionsModal}
          splitSubtitle={splitSubtitle}
          deleteSubtitle={deleteSubtitle}
          addSubtitleAfter={addSubtitleAfter}
          editInOutPointsHit={editInOutPointsHit}
          index={optionsIndex}
          subtitles={subtitles}
        />
      ) : null}
      {showDeleteSubtitlesModal ? (
        <DeleteSubtitlesPopup
          setShowDeleteSubtitlesModal={setShowDeleteSubtitlesModal}
          deleteAllSubtitlesConfirmed={deleteAllSubtitlesConfirmed}
        />
      ) : null}
      {showSendToProductionPopup ? (
        <SendToProductionPopup
          saveAndProduceHit={saveAndProduceHit}
          setShowSendToProductionPopup={setShowSendToProductionPopup}
        />
      ) : null}
      {showSavingChanges ? <SavingChanges /> : null}
      {showLoadingScreen ? <LoadingScreen /> : null}
      {showProducingVideoScreen ? <ProducingVideoPopup /> : null}
      {showShortcutsScreen ? (
        <ShortcutsScreen setShowShortcutsScreen={setShowShortcutsScreen} />
      ) : null}
      {showTutorial ? (
        <TutorialScreen setShowTutorial={setShowTutorial} />
      ) : null}
      {showMenuPopup ? (
        <MenuPopup
          saveChanges={saveChanges}
          saveAndProduceHit={saveAndProduceHit}
        />
      ) : null}
    </div>
  );
};

const Subtitle = (props) => {
  var active = props.activeSubtitleIndex == props.index;
  var content = "";
  if (props.item.content) {
    content = props.item.content;
  }
  return (
    <div
      className="subtitle w-full text-center mr-2 my-1 lg:my-4 first:mt-0"
      id={"sub-" + props.index}
    >
      <div className="wrapper item flex w-full text-center h-25 box-border">
        <div className="timecodes relative text-center flex flex-col justify-between px-2">
          <button
            onClick={() => props.editInOutPointsHit(props.index)}
            className="inPointBtn h-full text-[#4B5564] text-xs font-mono"
            style={{ lineHeight: "100%" }}
          >
            <span
              className={`border px-1 py-1 rounded ${active ? "border-[#FFFFFF] shadow-lg bg-white text-black" : "border-[#D0D0D0]"}`}
            >
              {convertTimestampToString(props.inPoint)}
            </span>
          </button>
          <button
            onClick={() => props.editInOutPointsHit(props.index)}
            className="outPointBtn h-full text-[#4B5564] text-xs font-mono"
            style={{ lineHeight: "100%" }}
          >
            <span
              className={`border px-1 py-1 rounded ${active ? "border-[#FFFFFF] shadow-lg bg-white text-black" : "border-[#D0D0D0]"}`}
            >
              {convertTimestampToString(props.outPoint)}
            </span>
          </button>
          <div className="line absolute w-px h-[20%] top-1/2 self-center bg-[#D0D0D0] -translate-y-1/2"></div>
        </div>
        <div
          onClick={() => props.goToSubtitle(props.index)}
          className={`content transition-shadow duration-100 shadow-lg hover:shadow-xl flex flex-col items-start w-full rounded-2xl my-1 mr-2 overflow-hidden ${active ? "bg-white shadow-lg" : "bg-white/80"}`}
        >
          <div className="flex relative w-full box-border">
            <div className="progress w-full h-full absolute inset-0 z-0"></div>
            <div className="inputContainer flex items-center py-4 z-10 w-full">
              <textarea
                onClick={() => props.subtitleHit(props.index)}
                onChange={(e) => props.subtitleChanged(e.target, props.index)}
                className={`input resize-none overflow-hidden text-sm bg-transparent w-full resize-none text-center ml-6 mr-10 text-black focus:outline-none`}
                value={content}
              />
            </div>
            <button
              className="optionsBtn w-8 h-8 mr-1 self-center absolute right-0 z-20 h-full"
              onClick={() => props.optionsHit(true, props.index)}
            >
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <g
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  stroke="#0C4C8A"
                  fill="none"
                  strokeLinejoin="round"
                >
                  <path d="M11.997 17.5c-.275 0-.5.225-.497.5 0 .275.225.5.5.5 .275 0 .5-.225.5-.5 0-.275-.225-.5-.503-.5"></path>
                  <path d="M11.997 11.5c-.275 0-.5.225-.497.5 0 .275.225.5.5.5 .275 0 .5-.225.5-.5 0-.275-.225-.5-.503-.5"></path>
                  <path d="M11.997 5.5c-.275 0-.5.225-.497.5 0 .275.225.5.5.5 .275 0 .5-.225.5-.5 0-.275-.225-.5-.503-.5"></path>
                </g>
              </svg>
            </button>
            {active ? (
              <div
                className="absolute w-full border-b border-[#0C4C8A]/50 bg-[#FFFC]"
                style={{
                  height:
                    ((props.currentTime - props.inPoint) /
                      (props.outPoint - props.inPoint)) *
                      80 +
                    "px",
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const CaptionFlowLogo = () => {
  return (
    <div className="h-20">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="50"
        viewBox="0 0 158.54 21.36"
      >
        <path
          d="M10.86,13.1c-.57,.34-1.26,.5-2.08,.5-1.17,0-2.13-.43-2.88-1.28s-1.13-1.92-1.13-3.2v-.05c0-1.25,.38-2.3,1.14-3.17,.76-.86,1.72-1.3,2.87-1.3,1.38,0,2.6,.66,3.67,1.97l3.5-2.71C14.3,1.54,11.92,.38,8.83,.38,6.3,.38,4.2,1.22,2.52,2.9,.84,4.58,0,6.66,0,9.12v.05c0,2.48,.84,4.55,2.52,6.2,1.68,1.66,3.73,2.48,6.14,2.48,1.73,0,3.17-.33,4.33-.98,1.16-.66,2.19-1.59,3.08-2.81l-3.5-2.5c-.58,.69-1.15,1.2-1.72,1.54Z"
          fill="#DDDDDD"
        />
        <path
          d="M23.54,4.34c-1.87,0-3.62,.34-5.26,1.01l.94,3.19c1.23-.48,2.43-.72,3.6-.72,1.73,0,2.59,.71,2.59,2.14v.22c-.86-.34-1.9-.5-3.12-.5-1.57,0-2.81,.35-3.72,1.04-.91,.7-1.37,1.71-1.37,3.04v.05c0,1.25,.4,2.23,1.21,2.94,.81,.71,1.85,1.07,3.13,1.07,1.5,0,2.78-.55,3.84-1.66v1.37h4.49v-7.46c0-1.84-.46-3.22-1.39-4.13-1.06-1.06-2.7-1.58-4.94-1.58Zm1.92,8.47c0,.66-.21,1.18-.64,1.56-.42,.38-.97,.58-1.64,.58-.48,0-.87-.13-1.16-.38-.3-.26-.44-.6-.44-1.03v-.05c0-.5,.18-.89,.54-1.18,.36-.29,.88-.43,1.55-.43s1.24,.11,1.8,.34v.6Z"
          fill="#DDDDDD"
        />
        <path
          d="M40.7,4.25c-1.52,0-2.83,.67-3.94,2.02v-1.73h-4.56V21.36h4.56v-5.42c1.09,1.25,2.41,1.87,3.96,1.87s2.96-.62,4.07-1.86c1.11-1.24,1.67-2.88,1.67-4.93v-.05c0-2.02-.56-3.64-1.68-4.87s-2.48-1.85-4.08-1.85Zm1.3,6.79c0,.86-.26,1.57-.78,2.12-.52,.55-1.15,.83-1.88,.83s-1.38-.28-1.9-.84c-.51-.56-.77-1.26-.77-2.11v-.05c0-.85,.26-1.55,.77-2.11,.51-.56,1.14-.84,1.9-.84s1.36,.28,1.88,.84c.52,.56,.78,1.26,.78,2.11v.05Z"
          fill="#DDDDDD"
        />
        <path
          d="M53.66,1.25h-4.54v3.29h-1.54v3.65h1.54v5.3c0,1.5,.37,2.6,1.1,3.28,.74,.68,1.82,1.02,3.24,1.02,1.17,0,2.22-.26,3.17-.77v-3.55c-.61,.29-1.18,.43-1.73,.43-.83,0-1.25-.43-1.25-1.3v-4.42h3.02v-3.65h-3.02V1.25Z"
          fill="#DDDDDD"
        />
        <rect x="58.68" width="4.73" height="3.36" fill="#DDDDDD" />
        <rect x="58.78" y="4.54" width="4.54" height="12.98" fill="#DDDDDD" />
        <path
          d="M72.55,4.25c-2.05,0-3.76,.66-5.12,1.98s-2.05,2.92-2.05,4.81v.05c0,1.89,.68,3.48,2.03,4.79,1.35,1.3,3.05,1.96,5.1,1.96s3.76-.66,5.14-1.98,2.06-2.92,2.06-4.81v-.05c0-1.87-.68-3.46-2.04-4.78-1.36-1.31-3.06-1.97-5.11-1.97Zm2.74,6.84c0,.8-.25,1.48-.76,2.04-.5,.56-1.16,.84-1.98,.84s-1.44-.29-1.97-.86c-.53-.58-.79-1.26-.79-2.06v-.05c0-.82,.25-1.5,.74-2.06s1.15-.84,1.97-.84,1.46,.29,1.99,.86c.53,.58,.79,1.27,.79,2.09v.05Z"
          fill="#DDDDDD"
        />
        <path
          d="M90.07,4.25c-1.5,0-2.8,.7-3.89,2.11v-1.82h-4.56v12.98h4.56v-7.03c0-.7,.18-1.25,.53-1.63,.35-.38,.82-.58,1.39-.58s1.03,.19,1.36,.58c.33,.38,.49,.93,.49,1.63v7.03h4.54V9.02c0-1.49-.39-2.66-1.16-3.5-.78-.85-1.86-1.27-3.25-1.27Z"
          fill="#DDDDDD"
        />
        <polygon
          points="97.1 17.52 101.76 17.52 101.76 11.52 109.7 11.52 109.7 7.66 101.76 7.66 101.76 4.8 110.54 4.8 110.54 .72 97.1 .72 97.1 17.52"
          fill="#DDDDDD"
        />
        <rect x="112.53" width="4.54" height="17.52" fill="#DDDDDD" />
        <path
          d="M126.31,4.25c-2.05,0-3.76,.66-5.12,1.98s-2.05,2.92-2.05,4.81v.05c0,1.89,.68,3.48,2.03,4.79,1.35,1.3,3.05,1.96,5.1,1.96s3.76-.66,5.14-1.98,2.06-2.92,2.06-4.81v-.05c0-1.87-.68-3.46-2.04-4.78-1.36-1.31-3.06-1.97-5.11-1.97Zm2.74,6.84c0,.8-.25,1.48-.76,2.04-.5,.56-1.16,.84-1.98,.84s-1.44-.29-1.97-.86c-.53-.58-.79-1.26-.79-2.06v-.05c0-.82,.25-1.5,.74-2.06s1.15-.84,1.97-.84,1.46,.29,1.99,.86c.53,.58,.79,1.27,.79,2.09v.05Z"
          fill="#DDDDDD"
        />
        <polygon
          points="149.54 4.54 147.79 11.11 145.77 4.49 142.03 4.49 140.01 11.06 138.31 4.54 133.87 4.54 137.83 17.62 141.72 17.62 143.88 10.85 145.99 17.62 149.93 17.62 153.93 4.54 149.54 4.54"
          fill="#DDDDDD"
        />
        <rect x="153.81" y="12.84" width="4.73" height="4.68" fill="#DDDDDD" />
      </svg>
    </div>
  );
};

const InOutPointEditor = (props) => {
  var minValue = 0;
  var maxValue = 0;

  var container = useRef(null);
  var labelInPoint = useRef(null);
  var labelOutPoint = useRef(null);

  const STEP = 1;
  const MIN = 0;
  const MAX = 100;
  const [values, setValues] = useState([0, 0]);

  useEffect(() => {
    if (props.activeSubtitleIndex > 0) {
      minValue =
        props.subtitles[Number(props.activeSubtitleIndex - 1)].outPoint;
    }
    if (
      props.subtitles.length > 1 &&
      props.activeSubtitleIndex < props.subtitles.length - 1
    ) {
      maxValue =
        props.subtitles[Number(props.activeSubtitleIndex + 1)].inPoint -
        minValue;
    } else {
      maxValue = props.videoDuration - minValue;
    }
    let convertedInPoint = maxValue * (values[0] / 100) + minValue;
    let convertedOutPoint = maxValue * (values[1] / 100) + minValue;

    labelInPoint.current.innerHTML = convertTimestampToString(
      convertedInPoint,
      true,
    );
    labelOutPoint.current.innerHTML = convertTimestampToString(
      convertedOutPoint,
      true,
    );
  }, [values]);

  useEffect(() => {
    if (props.activeSubtitleIndex > 0) {
      minValue =
        props.subtitles[Number(props.activeSubtitleIndex - 1)].outPoint;
    }
    if (
      props.subtitles.length > 1 &&
      props.activeSubtitleIndex < props.subtitles.length - 1
    ) {
      maxValue =
        props.subtitles[Number(props.activeSubtitleIndex + 1)].inPoint -
        minValue;
    } else {
      maxValue = props.videoDuration - minValue;
    }

    let convertedInPoint =
      props.subtitles[props.activeSubtitleIndex].inPoint - minValue;
    let convertedOutPoint =
      props.subtitles[props.activeSubtitleIndex].outPoint - minValue;

    setValues([
      (convertedInPoint / maxValue) * 100,
      (convertedOutPoint / maxValue) * 100,
    ]);
  }, []);

  const highlightSliders = () => {};

  const submitBtnHit = () => {
    if (props.activeSubtitleIndex > 0) {
      minValue =
        props.subtitles[Number(props.activeSubtitleIndex - 1)].outPoint;
    }
    if (
      props.subtitles.length > 1 &&
      props.activeSubtitleIndex < props.subtitles.length - 1
    ) {
      maxValue =
        props.subtitles[Number(props.activeSubtitleIndex + 1)].inPoint -
        minValue;
    } else {
      maxValue = props.videoDuration - minValue;
    }
    let convertedInPoint = maxValue * (values[0] / 100) + minValue;
    let convertedOutPoint = maxValue * (values[1] / 100) + minValue;

    props.subtitles[props.activeSubtitleIndex].inPoint = convertedInPoint;
    props.subtitles[props.activeSubtitleIndex].outPoint = convertedOutPoint;

    // props.setIsEditingInOutPoints(false);
  };

  const onDrag = (val) => {
    let inPointChanged = false;
    if (values[0] != val[0]) {
      inPointChanged = true;
    }
    if (inPointChanged) {
      let convertedInPoint = maxValue * (values[0] / 100) + minValue;
      props.setVideoCurrentTime(convertedInPoint);
    } else {
      let convertedOutPoint = maxValue * (values[1] / 100) + minValue;
      props.setVideoCurrentTime(convertedOutPoint);
    }

    setValues(val);
  };

  const onFinalChange = () => {
    props.setVideoCurrentTime(
      props.subtitles[props.activeSubtitleIndex].inPoint,
    );
    // Save changes instantly
    submitBtnHit();
  };

  return (
    <div className="flex absolute bottom-0 py-0 items-center w-full border-l border-[#D0D0CE] bg-[#F2F2F2]/95 justify-between">
      <button
        className="h-full shadow-md w-auto ml-4 mr-4 text-[#3E3E3E] py-1 bg-white rounded text-base font-mono"
        style={{ lineHeight: "100%" }}
      >
        <label
          ref={labelInPoint}
          className="text-xs text-center p-1 rounded w-20"
        />
      </button>
      <div
        ref={container}
        className="relative flex items-center w-full overflow-visible"
      >
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => {
            onDrag(values);
          }}
          onFinalChange={() => {
            onFinalChange();
          }}
          renderTrack={({ props, children }) => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values,
                    colors: ["#D0D0CE", "#0C4C8A", "#D0D0CE"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "20px",
                width: "20px",
                borderRadius: "20px",
                backgroundColor: "#0C4C8A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              <div
                style={{
                  height: "6px",
                  width: "6px",
                  borderRadius: "6px",
                  backgroundColor: isDragged ? "#D0D0CE" : "#CCC",
                }}
              />
            </div>
          )}
        />
      </div>
      <button
        onClick={() => highlightSliders()}
        className="h-full shadow-md w-auto ml-4 py-1 text-[#3E3E3E] bg-white rounded text-base font-mono"
        style={{ lineHeight: "100%" }}
      >
        <label
          ref={labelOutPoint}
          className="text-xs text-center p-1 rounded w-20"
        />
      </button>
    </div>
  );
};

const MenuPopup = (props) => {
  useEffect(() => {
    gsap.fromTo(
      "#bg",
      { opacity: 0 },
      { opacity: 1, duration: 0.25, ease: "Power2.easeOut" },
    );
    gsap.fromTo(
      "#box",
      { y: 100, opacity: 0 },
      { opacity: 1, y: 0, ease: "Power2.easeOut" },
    );
  }, []);
  return (
    <div className="flex flex-col justify-center items-center absolute w-full h-full z-40">
      <div
        id="bg"
        className="bg bg-[#000000]/80 absolute z-0 w-full h-full"
      ></div>
      <div
        id="box"
        className="relative content z-10 max-w-sm w-5/6 h-min rounded-xl overflow-hidden shadow-xl"
      >
        <ul>
          <li
            onClick={() => props.saveAndProduceHit()}
            className="group flex items-center justify-center text-center py-6 bg-[#FFFFFF] text-[#0C4C8A] cursor-pointer mt-px border-b"
          >
            <svg
              className="h-12 mr-4 transition duration-200 group-hover:translate-x-1"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none">
                <path d="M0,0h24v24h-24Z"></path>
                <path
                  stroke="#0C4C8A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.0"
                  d="M12.94,11.06l-7.07,7.07"
                ></path>
                <path
                  stroke="#0C4C8A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.0"
                  d="M6.633,12.999l-3.463,-0.818c-0.381,-0.09 -0.515,-0.563 -0.239,-0.84l2.754,-2.754c0.185,-0.185 0.436,-0.291 0.699,-0.293l3.105,-0.027"
                ></path>
                <path
                  stroke="#0C4C8A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.0"
                  d="M19.879,8.975l0.808,-3.888c0.22,-1.058 -0.716,-1.994 -1.774,-1.774l-3.888,0.808c-1.145,0.238 -2.195,0.805 -3.021,1.632l-3.19,3.189c-1.217,1.217 -2.009,2.795 -2.259,4.498l-0.011,0.073c-0.158,1.087 0.206,2.184 0.982,2.961v0c0.776,0.776 1.874,1.14 2.961,0.981l0.073,-0.011c1.703,-0.249 3.281,-1.042 4.498,-2.259l3.189,-3.189c0.827,-0.826 1.394,-1.876 1.632,-3.021Z"
                ></path>
                <path
                  stroke="#0C4C8A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.0"
                  d="M13.4,4.714c2.311,1.576 4.31,3.575 5.886,5.886"
                ></path>
                <path
                  stroke="#0C4C8A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.0"
                  d="M11,17.367l0.818,3.463c0.09,0.381 0.563,0.515 0.84,0.239l2.754,-2.754c0.185,-0.185 0.291,-0.436 0.293,-0.699l0.027,-3.105"
                ></path>
              </g>
            </svg>
            <span className="text-left font-medium">
              <FormattedMessage
                {...messages.sendCaptionToProduction}
                values={{ br: <br /> }}
              />
            </span>
          </li>
          <li
            onClick={() => props.saveChanges()}
            className="group flex items-center justify-center px-4 py-4 bg-[#FFFFFF] text-center text-[#0C4C8A] cursor-pointer"
          >
            <svg
              className="h-6 mr-4 transition duration-200 group-hover:translate-x-1"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g
                strokeLinecap="round"
                strokeWidth="1.5"
                stroke="#0C4C8A"
                fill="none"
                strokeLinejoin="round"
              >
                <path d="M12 3v14"></path>
                <path d="M9 14l3 3 3-3"></path>
                <path d="M8 10h-3c-1.105 0-2 .895-2 2v7c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2v-7c0-1.105-.895-2-2-2h-3"></path>
              </g>
              <path fill="none" d="M0 0h24v24h-24Z"></path>
            </svg>
            <FormattedMessage {...messages.saveChanges} />
          </li>
        </ul>
      </div>
    </div>
  );
};

const OptionsPopup = (props) => {
  let content = "";
  if (props.subtitles.length > 0) {
    content = '"' + props.subtitles[props.index].content + '"';
  }
  return (
    <div
      id="optionsModal"
      onClick={() => props.setShowOptionsModal(false)}
      className="flex flex-col justify-center items-center absolute w-full h-full z-50"
    >
      <div className="bg bg-[#F2F2F2]/90 absolute z-0 w-full h-full"></div>
      <div className="relative content z-10 max-w-sm w-5/6 h-min rounded-md shadow-xl">
        <label className="flex justify-center bg-[#F2F2F2] border-2 border-white border-b-0 p-5 text-[#3E3E3E] text-sm rounded-t text-center">
          <span className="w-4/5 font-bold">{content}</span>
        </label>
        <button
          onClick={() => props.setShowOptionsModal(false)}
          className="absolute z-2 -top-4 -right-2 bg-[#0C4C8A] rounded-full w-8 h-8"
        >
          <svg
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g fill="none">
              <path
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8 8l8 8"
              ></path>
              <path
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M16 8l-8 8"
              ></path>
            </g>
          </svg>
        </button>
        <ul>
          <li
            onClick={() => props.splitSubtitle(props.index)}
            className="group flex items-center text-center px-4 py-3 text-[#3E3E3E] bg-white hover:bg-white cursor-pointer"
          >
            <svg
              className="h-6 mr-4 transition duration-200 group-hover:translate-x-1"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none">
                <path d="M0 0h24v24h-24Z"></path>
                <path
                  stroke="#323232"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M4.875 3v3l1.06581e-14 1.50996e-07c8.33927e-08.552285.447715 1 1 1h12l-4.37114e-08-8.88178e-16c.552285 2.41411e-08 1-.447715 1-1v-3"
                ></path>
                <path
                  stroke="#323232"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M18.875 21v-3 0c0-.552285-.447715-1-1-1h-12 -4.37114e-08c-.552285 2.41411e-08-1 .447715-1 1 0 0 0 3.55271e-15 0 3.55271e-15v3"
                ></path>
                <path
                  stroke="#323232"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M2 10.536v2.928l1.11022e-14-1.50996e-07c-8.33927e-08.552285.447715 1 1 1 .21637 3.2671e-08.426904-.0701778.6-.2l1.952-1.464 5.57032e-08-4.17774e-08c.441828-.331371.531371-.958172.2-1.4 -.0568543-.0758057-.124194-.143146-.2-.2l-1.952-1.464 5.57032e-08 4.17774e-08c-.441828-.331371-1.06863-.241828-1.4.2 -.129822.173096-.2.38363-.2.6Z"
                ></path>
                <path
                  stroke="#323232"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M22 13.464v-2.928 -1.77636e-15c0-.552285-.447715-1-1-1 -.21637 0-.426904.0701779-.6.2l-1.952 1.464 -7.79577e-08 5.84683e-08c-.441828.331371-.531371.958172-.2 1.4 .0568543.0758057.124194.143146.2.2l1.952 1.464 -7.79577e-08-5.84683e-08c.441828.331371 1.06863.241828 1.4-.2 .129822-.173096.2-.38363.2-.6Z"
                ></path>
              </g>
            </svg>
            <FormattedMessage {...messages.splitSubtitleInTwo} />
          </li>
          <li
            onClick={() => props.addSubtitleAfter(props.index)}
            className="group flex items-center text-center px-4 py-3 text-[#3E3E3E] bg-white hover:bg-white cursor-pointer mt-px"
          >
            <svg
              className="h-6 mr-4 transition duration-200 group-hover:translate-x-1"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M5 16h-.556c-.797 0-1.444-.647-1.444-1.444v-10.112c0-.797.647-1.444 1.444-1.444h10.111c.798 0 1.445.647 1.445 1.444v.556"
                strokeLinecap="round"
                strokeWidth="1.5"
                stroke="#323232"
                fill="none"
                strokeLinejoin="round"
              ></path>
              <path
                fill="none"
                stroke="#323232"
                strokeWidth="1.5"
                d="M19.556 21h-10.112c-.797 0-1.444-.647-1.444-1.444v-10.112c0-.797.647-1.444 1.444-1.444h10.111c.798 0 1.445.647 1.445 1.444v10.111c0 .798-.647 1.445-1.444 1.445Z"
              ></path>
              <g
                strokeLinecap="round"
                strokeWidth="1.5"
                stroke="#323232"
                fill="none"
                strokeLinejoin="round"
              >
                <line x1="14.5" x2="14.5" y1="12" y2="17"></line>
                <line x1="17" x2="12" y1="14.5" y2="14.5"></line>
              </g>
              <path fill="none" d="M0 0h24v24h-24v-24Z"></path>
            </svg>
            <FormattedMessage {...messages.addSubtitleAfterThis} />
          </li>
          <li
            onClick={() => props.editInOutPointsHit(props.index)}
            className="group flex items-center text-center px-4 py-3 text-[#3E3E3E] bg-white hover:bg-white cursor-pointer mt-px"
          >
            <svg
              className="h-6 mr-4 transition duration-200 group-hover:translate-x-1"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M24,24H0V0H24Z" fill="none" />
              <path
                d="M15.3,17.3l3.5-3.5a.96667.96667,0,0,1,1.4,0h0l1.5,1.5a.96667.96667,0,0,1,0,1.4h0l-3.5,3.5a.90783.90783,0,0,1-.7.3H15V18A1.08024,1.08024,0,0,1,15.3,17.3Z"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                d="M12,21H8a5,5,0,0,1-5-5V8A5,5,0,0,1,8,3h8a5,5,0,0,1,5,5v3"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                d="M11.549,12.00006a.05.05,0,1,1-.05006-.05006.05.05,0,0,1,.05006.05006"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                d="M15.049,12.00006a.05.05,0,1,1-.05006-.05006.05.05,0,0,1,.05006.05006"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                d="M8.049,12.00006A.05.05,0,1,1,7.99893,11.95a.05.05,0,0,1,.05007.05006"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
            <FormattedMessage {...messages.changeTimestamps} />
          </li>
          <li
            onClick={() => props.deleteSubtitle(props.index)}
            className="group flex items-center text-center px-4 py-3 text-[#3E3E3E] bg-white hover:bg-white cursor-pointer mt-px rounded-b"
          >
            <svg
              className="h-6 mr-4 transition duration-200 group-hover:translate-x-1"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M15.54143,21H8.45857A2.25,2.25,0,0,1,6.2152,18.92257L5.25,6.375h13.5l-.9652,12.54757A2.25,2.25,0,0,1,15.54143,21Z"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <line
                x1="20"
                y1="6.375"
                x2="4"
                y2="6.375"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                d="M9.1875,3h5.625a1.125,1.125,0,0,1,1.125,1.125v2.25a0,0,0,0,1,0,0H8.0625a0,0,0,0,1,0,0V4.125A1.125,1.125,0,0,1,9.1875,3Z"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <line
                x1="13.96875"
                y1="10.875"
                x2="13.96875"
                y2="16.5"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <line
                x1="10.03125"
                y1="10.875"
                x2="10.03125"
                y2="16.5"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <FormattedMessage {...messages.deleteSubtitle} />
          </li>
        </ul>
      </div>
    </div>
  );
};
const ProducingVideoPopup = (props) => {
  useEffect(() => {
    gsap.fromTo("#rocket", { opacity: 0 }, { opacity: 1, duration: 0.5 });
    gsap.to("#rocket", {
      x: -40,
      y: 40,
      duration: 0.75,
      delay: 0.5,
      ease: "power2.easeOut",
    });
    gsap.to("#rocket", {
      opacity: 0,
      x: 250,
      y: -250,
      duration: 0.5,
      delay: 1.25,
      ease: "power2.easeIn",
    });
    gsap.fromTo("#txt", { opacity: 0 }, { duration: 1, opacity: 1, delay: 2 });
  }, []);
  return (
    <div
      id="optionsModal"
      className="flex flex-col justify-center items-center absolute w-full h-full z-50 overflow-hidden"
    >
      <div className="bg bg-[#0C4C8A] absolute z-0 w-full h-full"></div>
      <svg
        className="w-[500px] h-[500px] absolute bottom-[5%] left-[-250px] animate-rotateCogWheel"
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#0b4884"
          fill="none"
          strokeLinejoin="round"
        >
          <path d="M3.9 15.647l1.398.214c1.032.158 1.769 1.082 1.693 2.123l-.103 1.411c-.03.411.194.798.565.977l1.034.498c.371.179.814.112 1.117-.167l1.039-.96c.766-.708 1.948-.708 2.715 0l1.039.96c.303.28.745.346 1.117.167l1.036-.499c.37-.178.593-.564.563-.974l-.103-1.413c-.076-1.041.661-1.965 1.693-2.123l1.398-.214c.407-.062.735-.367.827-.769l.255-1.118c.092-.402-.071-.819-.411-1.051l-1.167-.799c-.861-.59-1.124-1.742-.604-2.647l.705-1.226c.205-.357.171-.804-.086-1.126l-.715-.897c-.257-.322-.685-.455-1.079-.334l-1.352.414c-.999.306-2.064-.207-2.448-1.178l-.518-1.313c-.152-.384-.523-.636-.936-.635l-1.146.003c-.413.001-.783.255-.933.64l-.505 1.298c-.38.977-1.45 1.494-2.452 1.186l-1.408-.432c-.395-.122-.825.012-1.082.336l-.71.898c-.257.325-.288.773-.079 1.13l.721 1.229c.531.906.271 2.069-.595 2.662l-1.153.79c-.34.233-.503.65-.411 1.051l.255 1.118c.091.403.419.708.826.77Z"></path>
          <path d="M13.916 10.084c1.058 1.058 1.058 2.774 0 3.832 -1.058 1.058-2.774 1.058-3.832 0 -1.058-1.058-1.058-2.774 0-3.832 1.058-1.058 2.774-1.058 3.832 0"></path>
        </g>
        <path fill="none" d="M0 0h24v24h-24v-24Z"></path>
      </svg>
      <svg
        className="w-[700px] h-[700px] absolute top-[5%] right-[-350px] animate-rotateCogWheel"
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#0b4884"
          fill="none"
          strokeLinejoin="round"
        >
          <path d="M3.9 15.647l1.398.214c1.032.158 1.769 1.082 1.693 2.123l-.103 1.411c-.03.411.194.798.565.977l1.034.498c.371.179.814.112 1.117-.167l1.039-.96c.766-.708 1.948-.708 2.715 0l1.039.96c.303.28.745.346 1.117.167l1.036-.499c.37-.178.593-.564.563-.974l-.103-1.413c-.076-1.041.661-1.965 1.693-2.123l1.398-.214c.407-.062.735-.367.827-.769l.255-1.118c.092-.402-.071-.819-.411-1.051l-1.167-.799c-.861-.59-1.124-1.742-.604-2.647l.705-1.226c.205-.357.171-.804-.086-1.126l-.715-.897c-.257-.322-.685-.455-1.079-.334l-1.352.414c-.999.306-2.064-.207-2.448-1.178l-.518-1.313c-.152-.384-.523-.636-.936-.635l-1.146.003c-.413.001-.783.255-.933.64l-.505 1.298c-.38.977-1.45 1.494-2.452 1.186l-1.408-.432c-.395-.122-.825.012-1.082.336l-.71.898c-.257.325-.288.773-.079 1.13l.721 1.229c.531.906.271 2.069-.595 2.662l-1.153.79c-.34.233-.503.65-.411 1.051l.255 1.118c.091.403.419.708.826.77Z"></path>
          <path d="M13.916 10.084c1.058 1.058 1.058 2.774 0 3.832 -1.058 1.058-2.774 1.058-3.832 0 -1.058-1.058-1.058-2.774 0-3.832 1.058-1.058 2.774-1.058 3.832 0"></path>
        </g>
        <path fill="none" d="M0 0h24v24h-24v-24Z"></path>
      </svg>
      <svg
        id="rocket"
        className="w-[128px] h-[128px] absolute"
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g fill="none">
          <path d="M0,0h24v24h-24Z"></path>
          <path
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M12.94,11.06l-7.07,7.07"
          ></path>
          <path
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M6.633,12.999l-3.463,-0.818c-0.381,-0.09 -0.515,-0.563 -0.239,-0.84l2.754,-2.754c0.185,-0.185 0.436,-0.291 0.699,-0.293l3.105,-0.027"
          ></path>
          <path
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M19.879,8.975l0.808,-3.888c0.22,-1.058 -0.716,-1.994 -1.774,-1.774l-3.888,0.808c-1.145,0.238 -2.195,0.805 -3.021,1.632l-3.19,3.189c-1.217,1.217 -2.009,2.795 -2.259,4.498l-0.011,0.073c-0.158,1.087 0.206,2.184 0.982,2.961v0c0.776,0.776 1.874,1.14 2.961,0.981l0.073,-0.011c1.703,-0.249 3.281,-1.042 4.498,-2.259l3.189,-3.189c0.827,-0.826 1.394,-1.876 1.632,-3.021Z"
          ></path>
          <path
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M13.4,4.714c2.311,1.576 4.31,3.575 5.886,5.886"
          ></path>
          <path
            stroke="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M11,17.367l0.818,3.463c0.09,0.381 0.563,0.515 0.84,0.239l2.754,-2.754c0.185,-0.185 0.291,-0.436 0.293,-0.699l0.027,-3.105"
          ></path>
        </g>
      </svg>
      <div className="flex flex-col justify-center items-center relative content z-10 w-3/4 lg:w-1/2 h-min">
        <label
          id="txt"
          className="flex flex-col justify-center p-5 text-white rounded text-center font-light"
        >
          <span className="text-xl font-bold">
            <FormattedMessage {...messages.goodJob} />!
          </span>
          <br />
          <span className="text-xl">
            <FormattedMessage {...messages.weAreProducingYourVideo} />
          </span>
        </label>
      </div>
    </div>
  );
};

const TutorialScreen = (props) => {
  return (
    <div
      id="optionsModal"
      className="flex flex-col justify-center p-8 items-center absolute w-full h-full z-50"
    >
      <div className="bg bg-[#F2F2F2]/90 absolute z-0 w-full h-full"></div>
      <div className="relative content z-30 w-full rounded-md shadow-xl">
        <button
          onClick={() => props.setShowTutorial(false)}
          className="absolute z-10 -top-4 -right-2 bg-[#0C4C8A] rounded-full w-9 h-9"
        >
          <svg
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g fill="none">
              <path
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8 8l8 8"
              ></path>
              <path
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M16 8l-8 8"
              ></path>
            </g>
          </svg>
        </button>
        <video
          id="videoContainer"
          className="relative block box-border object-contain w-full origin-top"
          width="1920"
          height="1080"
          playsInline={true}
          controls={true}
          poster="https://fliva-assets-eu-central.s3.eu-central-1.amazonaws.com/customers/fliva/captionflow_how-to_poster.jpg"
          src="https://fliva-assets-eu-central.s3.eu-central-1.amazonaws.com/customers/fliva/captionflow_how-to.mp4"
        ></video>
      </div>
    </div>
  );
};

const ShortcutsScreen = (props) => {
  return (
    <div
      id="optionsModal"
      onClick={() => props.setShowShortcutsScreen(false)}
      className="flex flex-col justify-center items-center absolute w-full h-full z-50"
    >
      <div className="bg bg-[#F2F2F2]/90 absolute z-0 w-full h-full"></div>
      <div className="relative content z-10 max-w-md w-5/6 h-min rounded-md shadow-xl">
        <button
          onClick={() => props.setShowShortcutsScreen(false)}
          className="absolute z-2 -top-4 -right-2 bg-[#0C4C8A] rounded-full w-8 h-8"
        >
          <svg
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g fill="none">
              <path
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8 8l8 8"
              ></path>
              <path
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M16 8l-8 8"
              ></path>
            </g>
          </svg>
        </button>
        <ul>
          <li className="group flex justify-between items-center text-center px-4 py-3 text-[#3E3E3E] bg-white">
            <span className="font-bold">CTRL + SPACE</span>
            <span className="text-[#AAAAAA]">
              <FormattedMessage {...messages.shortcutsPlayPause} />
            </span>
          </li>
          <li className="group flex justify-between items-center text-center px-4 py-3 text-[#3E3E3E] bg-white mt-px">
            <span className="font-bold">CTRL + SHIFT + BACKSPACE</span>
            <span className="text-[#AAAAAA]">
              <FormattedMessage {...messages.shorcutsDelete} />
            </span>
          </li>
          <li className="group flex justify-between items-center text-center px-4 py-3 text-[#3E3E3E] bg-white mt-px">
            <span className="font-bold">CTRL + SHIFT + D</span>
            <span className="text-[#AAAAAA]">
              <FormattedMessage {...messages.shorcutsDelete} />
            </span>
          </li>
          <li className="group flex justify-between items-center text-center px-4 py-3 text-[#3E3E3E] bg-white mt-px">
            <span className="font-bold">CTRL + SHIFT + S</span>
            <span className="text-[#AAAAAA]">
              <FormattedMessage {...messages.shorcutsSplit} />
            </span>
          </li>
          <li className="group flex justify-between items-center text-center px-4 py-3 text-[#3E3E3E] bg-white mt-px rounded-b">
            <span className="font-bold">CTRL + SHIFT + F</span>
            <span className="text-[#AAAAAA]">
              <FormattedMessage {...messages.shorcutsMaximize} />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

const DeleteSubtitlesPopup = (props) => {
  return (
    <div
      id="optionsModal"
      onClick={() => props.setShowDeleteSubtitlesModal(false)}
      className="flex flex-col justify-center items-center absolute w-full h-full z-50"
    >
      <div className="bg bg-[#F2F2F2]/90 absolute z-0 w-full h-full"></div>
      <div className="relative content z-10 max-w-sm w-5/6 h-min rounded-md shadow-xl">
        <label className="flex justify-center bg-[#F2F2F2] border-2 border-white border-b-0 p-5 text-[#3E3E3E] text-sm rounded-t text-center">
          <span className="w-4/5">
            <FormattedMessage {...messages.confirmDeleteAllSubtitles} />
          </span>
        </label>
        <ul>
          <li
            onClick={() => props.deleteAllSubtitlesConfirmed()}
            className="group flex items-center text-center font-bold px-4 py-3 text-[#3E3E3E] bg-white cursor-pointer mt-px"
          >
            <svg
              className="h-6 mr-4 transition duration-200 group-hover:translate-x-2"
              id="Layer_3"
              data-name="Layer 3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M15.54143,21H8.45857A2.25,2.25,0,0,1,6.2152,18.92257L5.25,6.375h13.5l-.9652,12.54757A2.25,2.25,0,0,1,15.54143,21Z"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <line
                x1="20"
                y1="6.375"
                x2="4"
                y2="6.375"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <path
                d="M9.1875,3h5.625a1.125,1.125,0,0,1,1.125,1.125v2.25a0,0,0,0,1,0,0H8.0625a0,0,0,0,1,0,0V4.125A1.125,1.125,0,0,1,9.1875,3Z"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <line
                x1="13.96875"
                y1="10.875"
                x2="13.96875"
                y2="16.5"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <line
                x1="10.03125"
                y1="10.875"
                x2="10.03125"
                y2="16.5"
                fill="none"
                stroke="#323232"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
              <rect width="24" height="24" fill="none" />
            </svg>
            <FormattedMessage {...messages.confirm} />
          </li>
          <li
            onClick={() => props.setShowDeleteSubtitlesModal(false)}
            className="group flex items-center text-center font-bold px-4 py-3 text-[#3E3E3E] bg-white cursor-pointer mt-px rounded-b"
          >
            <svg
              className="h-6 mr-4 transition duration-200 group-hover:translate-x-2"
              version="1.1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none">
                <path d="M0 0h24v24h-24Z"></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M6 7.5l3-3"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M6 7.5l3 3"
                ></path>
                <path
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M6 7.5h6c3.314 0 6 2.686 6 6 0 3.314-2.686 6-6 6 -3.314 0-6-2.686-6-6"
                ></path>
              </g>
            </svg>
            <FormattedMessage {...messages.cancel} />
          </li>
        </ul>
      </div>
    </div>
  );
};

const SendToProductionPopup = (props) => {
  useEffect(() => {
    gsap;
  }, []);
  return (
    <div className="absolute w-full h-full bg-slate-900/90 z-50">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-3/4 max-w-md bg-white rounded overflow-hidden">
          <div className="pt-4 pb-3 px-20 text-center border-b">
            <label className="font-bold font-xl">
              <FormattedMessage {...messages.sendToProduction} />
            </label>
          </div>
          <div className="pt-4 pb-6 px-20 text-center">
            <label>
              <FormattedMessage {...messages.sendToProductionDescription} />
            </label>
          </div>
          <div className="w-full flex">
            <button
              onClick={() => props.setShowSendToProductionPopup(false)}
              className="bg-[#FFFFFF] px-4 py-2 w-full border-t text-[#999999] hover:text-[#333333]"
            >
              <FormattedMessage {...messages.cancel} />
            </button>
            <button
              onClick={() => props.saveAndProduceHit()}
              className="text-white px-4 py-2 w-full bg-brand-500 hover:bg-brand-400 active:bg-brand-500"
            >
              <FormattedMessage {...messages.confirm} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SavingChanges = (props) => {
  return (
    <div className="absolute w-full h-full bg-slate-900/90 z-50">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Player
          keepLastFrame={true}
          autoplay
          loop
          src={animationData}
          style={{ height: "155px", width: "90px" }}
        ></Player>
        <label className="text-white text-sm mt-8 text-center">
          <FormattedMessage {...messages.waitWhileWeSaveYourChanges} />
        </label>
      </div>
    </div>
  );
};

const LoadingScreen = (props) => {
  return (
    <div className="absolute w-full h-full bg-[#F2F2F2]/90 z-50">
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Player
          keepLastFrame={true}
          autoplay
          loop
          src={animationData}
          style={{ height: "155px", width: "90px" }}
        ></Player>
      </div>
    </div>
  );
};

const SubtitleBackgroundToggle = (props) => {
  const handleChange = () => {
    props.setShowSubtitleBackground(!props.showSubtitleBackground);
  };
  return (
    <button
      onClick={() => handleChange()}
      className="absolute right-2 ml-2 mt-2 flex items-center z-40 bg-brand-500 hover:bg-brand-400 active:bg-brand-500 rounded shadow-md px-1 py-1"
    >
      <div>
        {props.showSubtitleBackground == true ? (
          <svg
            className="w-6 h-6 stroke-white"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g fill="none">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M16 10l-5 5 -3-3"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18 20h-12c-1.105 0-2-.895-2-2v-12c0-1.105.895-2 2-2h12c1.105 0 2 .895 2 2v12c0 1.105-.895 2-2 2Z"
              ></path>
            </g>
          </svg>
        ) : (
          <svg
            className="w-6 h-6 stroke-white"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g fill="none">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18 20h-12c-1.105 0-2-.895-2-2v-12c0-1.105.895-2 2-2h12c1.105 0 2 .895 2 2v12c0 1.105-.895 2-2 2Z"
              ></path>
            </g>
          </svg>
        )}
      </div>
      <label className="mx-1 font-bold text-xs text-white pointer-events-none">
        <FormattedMessage {...messages.subtitleBackgroundToggleLabel} />
      </label>
    </button>
  );
};

const PlayToPauseIcon = (props) => {
  return (
    <Player
      autoplay={true}
      loop={false}
      keepLastFrame={true}
      src={playToPauseAnimationData.default}
      style={{ height: "28px", width: "28px" }}
    ></Player>
  );
};

const PauseToPlayIcon = (props) => {
  return (
    <Player
      autoplay={true}
      keepLastFrame={true}
      loop={false}
      src={pauseToPlayAnimationData.default}
      style={{ height: "28px", width: "28px" }}
    ></Player>
  );
};

const WrappedCaptionFlow = () => {
  return (
    <>
      <Routes>
        <Route
          path={`:videoId/:captionId`}
          element={<CaptionFlowApiWrapper />}
        />
      </Routes>
      <Outlet />
    </>
  );
};

export default WrappedCaptionFlow;
