import React, { useState, useRef, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo";
import { Routes, Route, Link, Outlet, useParams } from "react-router-dom";
import Editor from "../../../player-builder/PlayerEditor";
import {
  WorkflowInstanceConnections,
  WorkflowConnections,
  AccountConnections,
  WorkflowInstance,
} from "../../../types";
import { timeDistanceInWords } from "../../../helpers/TimeInWords";
import Loading from "../../loading";
import "ace-builds/src-noconflict/mode-json";
import {
  INSTANCE_SHOW,
  RESTART_INSTANCE,
  InstanceShowResult,
} from "./Instance/Query";

const RenderingProgress = ({ event }) => {
  const payload = JSON.parse(event.payload);
  if (payload.progress) {
    const progress = payload.progress;
    console.log(progress);
    const percentComplete = progress.complete;
    const framesLeft = progress.total - progress.current;

    const timeLeft = Math.round(100.0 * (framesLeft / progress.fps)) / 100.0;
    return (
      <progress value={percentComplete} max="100">
        {percentComplete}% {timeLeft}s
      </progress>
    );
  } else {
    return <span />;
  }
};

const InstanceRendering = ({ rendering }) => {
  if (rendering == undefined) return <span></span>;
  return (
    <div>
      <h2 className="text-md">
        Rendering ({rendering?.renderrRendering?.id}): {rendering.state}
      </h2>
      <div>
        <h3 className="mt-4">Latest Events</h3>
        <div className="flex flex-col">
          Progress: <RenderingProgress event={rendering.events.items[0]} />
          {rendering.events.items.map((event) => {
            return (
              <div className="p-4 w-full" key={event.id}>
                <div className="text-sm">
                  {event.type}
                  <span className="text-xs pl-4">
                    ({timeDistanceInWords(event.createdAt)})
                  </span>
                </div>
                <div className="text-xs text-gray-900 w-full">
                  <textarea className="w-full">{event.payload}</textarea>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const InstanceViewable = ({ viewable }) => {
  return (
    <div>
      {/* <a href={viewable.embedUrl} target="_blank" className="inline-block my-4 bg-brand-500 hover:bg-brand-400 active:bg-brand-600 text-white text-sm font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Open video</a> */}
      <a
        href={viewable.embedUrl}
        target="_blank"
        className="flex relative flex-col justify-center items-center w-1/2 shadow-xl rounded-xl overflow-hidden bg-brand-500"
        style={{ minHeight: "450px" }}
      >
        <img
          src={viewable.publicPoster}
          className="w-full h-full absolute z-10"
        />
        <span className="z-20">
          <svg
            className="w-16 h-16"
            version="1.1"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g fill="none">
              <path d="M0,0h24v24h-24Z"></path>
              <path
                stroke="#FFFFFF"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.0"
                d="M21,12v0c0,4.971 -4.029,9 -9,9v0c-4.971,0 -9,-4.029 -9,-9v0c0,-4.971 4.029,-9 9,-9v0c4.971,0 9,4.029 9,9Z"
              ></path>
              <path
                stroke="#FFFFFF"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.0"
                d="M10.941,9.058l3.882,2.296c0.491,0.29 0.491,1.001 0,1.291l-3.882,2.296c-0.5,0.296 -1.132,-0.065 -1.132,-0.646v-4.591c-1.77636e-15,-0.581 0.632,-0.942 1.132,-0.646Z"
              ></path>
            </g>
          </svg>
        </span>
        <label className="z-20 font-bold text-sm text-white">
          {viewable.title}
        </label>
      </a>
      {viewable.captions.length == 0 ? (
        <h2 className="text-grey-300">No captions</h2>
      ) : (
        <div>
          {viewable.captions.map((caption) => {
            return (
              <div key={caption.id}>
                <h3>{caption.text ? "Caption saved" : "Caption not saved"}</h3>
                <a className="mr-2" href={caption.actionUrl} target="_blank">
                  Edit captions
                </a>
                <a
                  className="ml-2"
                  href={`${caption.actionUrl}#flow`}
                  target="_blank"
                >
                  CaptionFlow
                </a>
              </div>
            );
          })}
          <div>
            {viewable.captionKey ? "Caption rendered" : "Caption not rendered"}
          </div>
        </div>
      )}
    </div>
  );
};

const ShowJsonData = ({ jsonData, title, onChange }) => {
  return (
    <div className="w-1/2 p-1">
      <h2 className="text-md">{title}</h2>
      <Editor
        mode="json"
        update={onChange}
        value={JSON.stringify(JSON.parse(jsonData), null, "\t")}
      />
    </div>
  );
};

const AdminInstance = () => {
  const params = useParams();
  const [pollInterval, setPollInterval] = useState(1000);
  const vars = { id: params.instanceId };

  let localData = useRef({});

  const { loading, error, data, refetch } = useQuery<InstanceShowResult>(
    INSTANCE_SHOW,
    {
      variables: vars,
      pollInterval: pollInterval,
      fetchPolicy: "network-only",
    },
  );
  const [restartWorkflow, { data: savedData }] = useMutation(RESTART_INSTANCE);

  if (savedData) {
    refetch();
  }

  if (loading) return <Loading />;
  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (data?.workflowInstance?.viewable != null && pollInterval < 10000) {
    setPollInterval(10000);
  }

  const parentData = data.workflowInstance?.parent?.originalData
    ? JSON.parse(data.workflowInstance.parent.originalData)
    : {};

  return (
    <div>
      <h1 className="text-lg border-b p-1 mb-4">
        {data.workflowInstance.videoWorkflow.title} -{" "}
        {data.workflowInstance.status}
      </h1>
      <button
        onClick={() =>
          restartWorkflow({
            variables: {
              id: params.instanceId,
              data: localData.current["data"] || null,
            },
          })
        }
        type="button"
        className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
      >
        Restart Workflow
      </button>

      <Actions workflowInstance={data.workflowInstance} />
      <div className="flex my-2 border-b p-4">
        <div className="w-1/2">
          <div>
            {data.workflowInstance.viewable ? (
              <InstanceViewable viewable={data.workflowInstance.viewable} />
            ) : (
              <h1>No viewable</h1>
            )}
          </div>
          <div>
            {data.workflowInstance.parent ? (
              <div>
                <h1>Parent</h1>
                <Link
                  key={data.workflowInstance.parent.id}
                  to={`/admin/instance/${data.workflowInstance.parent.id}`}
                >
                  <h2>
                    {parentData["resolution"]} :{" "}
                    {data.workflowInstance.parent.status}
                  </h2>
                </Link>
              </div>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          <div>
            {data.workflowInstance.children &&
            data.workflowInstance.children.length > 0 ? (
              <div>
                <h1>Children</h1>
                {data.workflowInstance.children.map((child) => {
                  const data = JSON.parse(child.originalData);
                  return (
                    <Link key={child.id} to={`/admin/instance/${child.id}`}>
                      <h2>
                        {data["resolution"]} : {child.status}
                      </h2>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </div>
        <div className="w-1/2">
          {data.workflowInstance.rendering ? (
            <InstanceRendering rendering={data.workflowInstance.rendering} />
          ) : (
            <h1>No rendering</h1>
          )}
        </div>
      </div>
      <div className="flex my-2 border-b p-4">
        {data.workflowInstance.originalData ? (
          <ShowJsonData
            jsonData={data.workflowInstance.originalData}
            title="Original Data"
            onChange={(value) => {
              localData.current["data"] = value;
            }}
          />
        ) : (
          <span />
        )}
        {data.workflowInstance.data ? (
          <ShowJsonData
            jsonData={data.workflowInstance.data}
            title="Generated Data"
            onChange={(value) => {}}
          />
        ) : (
          <span />
        )}
      </div>
    </div>
  );
};

const Action = ({ action, titlePrefix, instance }) => {
  const bgColor =
    action.status == "Done"
      ? "bg-green-200"
      : action.status == "Failed"
        ? "bg-red-booboo"
        : "bg-white";

  const data = JSON.parse(instance.originalData);
  return (
    <div className={`w-1/4 ${bgColor} border-1 mx-2 p-2`}>
      <h1 className="text-lg border-b">
        {titlePrefix}: {action.title}
      </h1>
      <div className="flex flex-col">
        {data.subtitles == "no" && action.title == "CreateCaptions" ? (
          <span>Captions disabled</span>
        ) : (
          <>
            <span>Created: {timeDistanceInWords(action.createdAt)}</span>
            <span>
              Started:{" "}
              {action.startedAt ? timeDistanceInWords(action.startedAt) : "no"}
            </span>
            <span>
              Done: {action.doneAt ? timeDistanceInWords(action.doneAt) : "no"}
            </span>
            <span>
              Failed:{" "}
              {action.failedAt ? timeDistanceInWords(action.failedAt) : "no"}
            </span>
            <span>Status: {action.status} </span>
            {action.title == "CreateCaptions" ? (
              <button>Restart</button>
            ) : (
              <span />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const PreRenderingAction = ({ action, instance }) => {
  return (
    <Action action={action} instance={instance} titlePrefix="PreRendering" />
  );
};

const PreDeliveryAction = ({ action, instance }) => {
  return (
    <Action action={action} instance={instance} titlePrefix="PreDelivery" />
  );
};

const Actions = ({ workflowInstance }) => {
  return (
    <div className="flex">
      {workflowInstance.preRenderingActions.map((action) => {
        return (
          <PreRenderingAction action={action} instance={workflowInstance} />
        );
      })}
      {workflowInstance.preDeliveryActions.map((action) => {
        return (
          <PreDeliveryAction action={action} instance={workflowInstance} />
        );
      })}
    </div>
  );
};

export default AdminInstance;
